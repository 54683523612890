import * as firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBSBxcytLdpbSjbxv797OxbAc1sJMi3JR8",
  authDomain: "file-uploader-9dc09.firebaseapp.com",
  projectId: "file-uploader-9dc09",
  storageBucket: "file-uploader-9dc09.appspot.com",
  messagingSenderId: "184441344841",
  appId: "1:184441344841:web:25bf3446649c9b88988098",
  measurementId: "G-DGPPZBCQ8X"
};

export const app = firebase.initializeApp(firebaseConfig);
